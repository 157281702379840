<!--
 * @Description: 用户基本信息
 * @Author: ChenXueLin
 * @Date: 2021-09-03 09:06:54
 * @LastEditTime: 2022-04-22 14:52:59
 * @LastEditors: LiangYiNing
-->
<template>
  <section
    class="edit-wrapper"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="edit-wrapper__body">
      <!-- 头部状态 -->
      <detail-title-content :btnType="1" btnText="任务单状态:进行中">
        <template v-slot:rightButtons>
          <el-button type="primary" @click="restPassword">重置密码</el-button>
          <el-button
            type="primary"
            @click="enableUser"
            v-if="detailForm.userStatus == '禁用'"
            >启用账号</el-button
          >
          <el-button type="primary" @click="disableUser" v-else
            >停用账号</el-button
          >
          <el-button
            type="primary"
            class="e6-icon-edit_line"
            title="编辑"
            @click="editDetail"
          ></el-button>
        </template>
      </detail-title-content>
      <!-- 基本信息 -->
      <template>
        <div class="baseInfo-content-wrap">
          <div class="detailInfo-content">
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <!-- <detail-table-title></detail-table-title> -->
              <div class="edit-title">基本信息</div>
              <el-form
                label-width="140px"
                style="margin-top:20px;"
                :model="detailForm"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="用户ID" prop="id">
                      {{ detailForm.userId }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户名称" prop="userName">
                      {{ detailForm.userName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="联系电话" prop="phone">
                      {{ detailForm.phone }}
                    </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="用户类型" prop="userType">
                      {{ detailForm.userType }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="所属企业" prop="userCorp">
                      {{ detailForm.userCorp }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="所属部门" prop="userFramework">
                      {{ detailForm.userFramework }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="职级" prop="userDuty">
                      {{ detailForm.userDuty }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="岗位" prop="userPost">
                      {{ detailForm.userPost }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="工号" prop="userCode">
                      {{ detailForm.userCode }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="分机号" prop="phoneExt">
                      {{ detailForm.phoneExt }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建人" prop="createdBy">
                      {{ detailForm.createdBy }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="创建时间" prop="createdTime">
                      {{ detailForm.createdTime }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="用户角色" prop="userRole">
                      {{ detailForm.userRole.join(",") }}
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 基本信息 end  -->
            <!-- 此功能先不做 -->
            <!-- <div class="change-type-box">
              <div class="type active">调度员</div>
              <div class="type">工程师</div>
              <div class="type">客服</div>
            </div> -->
            <!-- <div class="edit-title">工程师信息</div>
            <div>
              <el-form
                label-width="140px"
                label-position="right"
                style="margin-top:20px;"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="名下设备">
                      <template>
                        <span style="margin-right:30px;">{{ equipNum }}</span>
                        <el-button type="text" @click="lookEquipment(1)"
                          >查看</el-button
                        >
                      </template>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="名下任务项">
                      <template>
                        <span style="margin-right:30px;">{{ taskNum }}</span>
                        <el-button type="text" @click="lookEquipment(2)"
                          >查看</el-button
                        >
                      </template>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>

            <detail-table-title :showSearch="false">
              <template v-slot:title>
                负责区域
              </template>
              <template v-slot:btns>
                <el-button type="primary" @click="toAddressManage"
                  >编辑负责区域</el-button
                >
              </template>
            </detail-table-title>
            <div class="table-box">
              <el-table
                border
                :data="areaList"
                highlight-current-row
                class="elTable"
              >
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                  
                >
                </el-table-column>
              </el-table>
            </div> -->
            <!-- 认证信息  start -->
            <detail-table-title :showSearch="false">
              <template v-slot:title>
                认证信息
              </template>
              <template v-slot:btns>
                <el-button type="primary" @click="handleEditBank"
                  >编辑认证信息</el-button
                >
              </template>
            </detail-table-title>
            <div class="form-div">
              <el-form
                label-width="140px"
                label-position="right"
                :model="authInfo"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="身份证号码">
                      <template>
                        <span style="margin-right:30px;">
                          {{ idCard }}
                        </span>
                        <el-button type="text" @click="seeMore('idCard')"
                          >查看完整</el-button
                        >
                      </template>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开户银行">
                      {{ authInfo.bank }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="银行卡号">
                      <template>
                        <span style="margin-right:30px;">
                          {{ bankCard }}
                        </span>
                        <el-button type="text" @click="seeMore('bankCard')"
                          >查看完整</el-button
                        >
                      </template>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开户支行">
                      {{ authInfo.branch }}
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 认证信息  end -->

            <!-- 业务数据 start  先不做-->
            <!-- <div class="edit-title">业务数据</div>
            <div class="score-data">
              <div class="score-box">
                <div class="score-til">累计</div>
                <el-row>
                  <el-col :span="18">
                    <el-row>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score score-last">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="score score-last">
                          <div>321</div>
                          <div class="e6-icon-order-completed score-title">
                            累计完成任务
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <div class="star-content">
                      <div class="score-number">6.8</div>
                      <div>
                        <el-rate v-model="value"> </el-rate>
                      </div>
                      <div>评分</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="score-box score-box2">
                <div class="date-picker">
                  <el-date-picker
                    v-model="dateVal"
                    type="month"
                    popper-class="special"
                    format="yyyy年MM月"
                  >
                  </el-date-picker>
                  <i class="e6-icon-arrow-b_line"></i>
                </div>
                <el-row>
                  <el-col :span="6">
                    <div class="score">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="score">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="score score-last">
                      <div>321</div>
                      <div class="e6-icon-order-completed score-title">
                        累计完成任务
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div> -->
            <!-- 业务数据 end -->
          </div>
        </div>
      </template>
    </div>
    <!-- 编辑基本信息  start -->
    <add-user-dialog
      :detailForm="addForm"
      :addUserVisible="addUserVisible"
      type="edit"
      :roleList="roleList"
      :userId="userId"
      @handleClose="handleClose"
      @refreshData="initData"
    ></add-user-dialog>
    <!-- 编辑基本信息  end -->
    <!-- 编辑认证信息  start -->
    <el-dialog
      v-dialogDrag
      title="编辑认证信息"
      :visible.sync="editAuthDialog"
      width="500px"
      :before-close="closeApproveInfo"
      :close-on-click-modal="false"
      v-loading="editAuthDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-dialog"
    >
      <el-form
        ref="editAuthForm"
        label-width="88px"
        :model="editAuthForm"
        :rules="editAuthFormRules"
      >
        <el-form-item label="身份证号" prop="idCard">
          <el-input
            v-model.trim="editAuthForm.idCard"
            placeholder="身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankCard">
          <el-input
            v-model.trim="editAuthForm.bankCard"
            placeholder="银行卡号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input
            v-model.trim="editAuthForm.bank"
            placeholder="开户银行"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户支行" prop="branch">
          <el-input
            v-model.trim="editAuthForm.branch"
            placeholder="开户支行"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeApproveInfo">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑认证信息  end -->
  </section>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
import detailTableTitle from "@/components/detailTableTitle";
import { bankNoValid, IDNOValid } from "@/utils/validate";
import {
  disableAccount,
  getBasic,
  enableAccount,
  getUserAuth,
  editUserAuth,
  resetPwd,
  getUserRoleList
  // engineerArea,
  // engineerAuth,
  // engineerUpdateAuth
  // engineerTaskList,
  // getEngineerEquipment
} from "@/api";
import { printError } from "@/utils/util";
import AddUserDialog from "../addUserDialog.vue";
export default {
  name: "userDetail",
  components: {
    detailTitleContent,
    detailTableTitle,
    AddUserDialog
  },
  data() {
    return {
      loading: false,
      userId: "", //用户id
      roleList: [],
      detailForm: {
        id: "", //用户id
        userName: "", //用户名称
        phone: "", //手机号
        userRole: [], //用户角色
        userType: "", //用户类型
        userCorp: "", //所属企业
        userFramework: "", //所属部门
        userDuty: "", //职级
        userPost: "", //岗位
        userCode: "", //工号
        phoneExt: "", //分机号
        createdBy: "", //创建人
        createdTime: "" //创建时间
      },
      addForm: {
        userCode: "", //工号
        userName: "", //用户名称
        phone: "", //手机号
        phoneExt: "", //分机号
        userRoleId: [], //用户角色
        userDutyId: "", //职级
        userPostId: "", //岗位
        userFrameworkId: [] //所属部门
      },
      columnData: [
        {
          fieldName: "areaType",
          display: true,
          fieldLabel: "区域类型",
          width: 300,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "区域",
          width: 300,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //负责区域表头
      authInfo: {
        idCard: "", //身份证号
        bankCard: "", //银行卡号
        bank: "", //开户银行
        branch: "" //开户支行
      }, //认证信息
      equipNum: 13, //名下设备数量
      taskNum: 13, //名下任务项数量
      idCard: "", //完整的身份证号
      bankCard: "", //完整的银行卡号
      areaList: [], //负责区域列表
      /****编辑基本信息****/
      addUserVisible: false,
      /**编辑认证信息 */
      editAuthDialog: false,
      editAuthDialogLoading: false,
      editAuthForm: {
        idCard: "", //身份证号
        bankCard: "", //银行卡号
        bank: "", //开户银行
        branch: "" //开户支行
      },
      editAuthFormRules: {
        idCard: IDNOValid.required({
          requiredMsg: "请输入身份证号",
          message: "身份证号格式不正确",
          trigger: ["blur", "change"]
        }),
        bankCard: bankNoValid.required({
          requiredMsg: "请输入银行卡号",
          message: "银行卡号格式不正确",
          trigger: ["blur", "change"]
        }),
        branch: [
          {
            required: true,
            message: "请输入开户支行",
            trigger: ["blur", "change"]
          }
        ],
        bank: [
          {
            required: true,
            message: "请输入开户银行",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["activeName"],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    activeName(val) {
      if (val == "one") {
        this.initData();
      }
    }
  },
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.userId = hasParamsRoute.params.userId;
    this.initData();
  },
  methods: {
    async initData() {
      if (!this.userId) {
        return;
      }
      this.loading = true;
      let promiseList = [
        getBasic({ id: this.userId }),
        getUserAuth({ id: this.userId }),
        getUserRoleList([])
      ];
      let [userDetailRes, authRes, roleRes] = await Promise.all(promiseList);
      this.detailForm = _.cloneDeep(userDetailRes.data);
      this.addForm = _.cloneDeep(userDetailRes.data);
      this.authInfo = _.cloneDeep(authRes.data);
      this.editAuthForm = _.cloneDeep(authRes.data);
      this.idCard = this.handleNum(_.cloneDeep(this.authInfo.idCard)); //完整的身份证号
      this.bankCard = this.handleNum(_.cloneDeep(this.authInfo.bankCard)); //完整的身份证号
      this.loading = false;
      // //用户角色下拉框
      this.roleList = this.getFreezeResponse(roleRes, {
        path: "data"
      });
    },

    // 停用账号
    async disableUser() {
      try {
        this.loading = true;
        let res = await disableAccount({
          id: this.detailForm.userId,
          versionNumber: this.detailForm.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("停用成功");
          this.initData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //重置密码
    restPassword() {
      this.$confirm("是否确认重置密码？", "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.resetPwdReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    async resetPwdReq() {
      try {
        this.loading = true;
        let res = await resetPwd({
          id: this.detailForm.userId
        });
        if (res.code == "OK") {
          this.$message.success("重置成功");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 启用账号
    async enableUser() {
      try {
        this.loading = true;
        let res = await enableAccount({
          id: this.detailForm.userId,
          versionNumber: this.detailForm.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.queryDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理卡号
    handleNum(value) {
      if (value && value.length > 5) {
        let reg = /^(\d{3})(\d*)(\d{4})$/;
        let str = value.replace(reg, (a, b, c, d) => {
          return b + c.replace(/\d/g, "*") + d;
        });
        return str;
      } else {
        return value;
      }
    },
    //查看完整
    seeMore(type) {
      if (type == "idCard") {
        if (this.idCard.indexOf("*") > -1) {
          this.idCard = this.authInfo.idCard;
        } else {
          this.idCard = this.handleNum(_.cloneDeep(this.authInfo.idCard));
        }
      } else {
        if (this.bankCard.indexOf("*") > -1) {
          this.bankCard = this.authInfo.bankCard;
        } else {
          this.bankCard = this.handleNum(_.cloneDeep(this.authInfo.bankCard));
        }
      }
    },
    /***   编辑基本信息 start *****/
    //点击编辑
    editDetail() {
      this.addUserVisible = true;
    },
    //关闭编辑弹框
    handleClose() {
      this.addUserVisible = false;
    },

    /******编辑认证信息********/
    //点击编辑认证信息
    handleEditBank() {
      this.editAuthDialog = true;
      this.editAuthForm = _.cloneDeep(this.authInfo);
    },
    //点击确定添加
    handleSubmit() {
      this.$refs.editAuthForm.validate(valid => {
        if (valid) {
          this.updateAuth();
        }
      });
    },
    //编辑认证信息请求
    async updateAuth() {
      try {
        this.editAuthDialogLoading = true;
        let res = await editUserAuth({
          ...this.editAuthForm,
          userId: this.userId
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.initData();
          this.closeApproveInfo();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editAuthDialogLoading = false;
      }
    },
    //关闭认证信息弹框
    closeApproveInfo() {
      this.editAuthForm = {
        idCard: "", //身份证号
        bankCard: "", //银行卡号
        bank: "", //开户银行
        branch: "" //开户支行
      };
      this.$refs.editAuthForm.resetFields();
      this.editAuthDialog = false;
    },
    //查看名下设备/名下任务项
    lookEquipment(type) {
      if (type == 1) {
        this.routerPush({
          name: "userManage/ownEquipment",
          params: {
            refresh: false
          }
        });
      } else {
        this.routerPush({
          name: "userManage/ownTask",
          params: {
            refresh: false
          }
        });
      }
    },
    //点击编辑负责区域
    toAddressManage() {
      this.routerPush({
        name: "operationsCenter/areaManage",
        params: {
          refresh: false
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper .edit-wrapper .edit-wrapper__body {
  height: 100%;
}
.detailInfo-content {
  .change-type-box {
    margin-top: 0px;
    height: 35px;
    display: flex;
    margin-left: 15px;
    box-sizing: border-box;
    .type {
      display: inline-block;
      text-align: center;
      line-height: 35px;
      color: #929395;
      font-size: 16px;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      border-top: 1px solid #dedfe1;
      border-bottom: 1px solid #dedfe1;
      border-right: 1px solid #dedfe1;
      &:nth-of-type(1) {
        border-left: 1px solid #dedfe1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-last-of-type(1) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .active {
      color: #fff;
      background: #46bfea;
      &:nth-of-type(1) {
        border-right: 1px solid #46bfea;
        border-left: 1px solid #46bfea;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-last-of-type(1) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .user-title {
    width: calc(100% - 30px);
    margin: 0 auto;
    // margin-top: 20px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    background: #f6f7fb;
    font-size: 14px;
    padding-left: 20px;
    box-sizing: border-box;
    color: #505155;
  }

  .table-box {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .score-data {
    width: 100%;
    margin-bottom: 50px;
    padding-top: 20px;
    box-sizing: border-box;
    .score-box {
      position: relative;
      .score-til {
        font-size: 14px;
        background: linear-gradient(to right, #17cdf2 0%, #1a96f8 100%);
        position: absolute;
        left: 0px;
        top: 0px;
        color: #fff;
        height: 30px;
        line-height: 30px;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      .score {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 55px;
        position: relative;
        & > :nth-child(1) {
          font-size: 22px;
          font-weight: 500;
        }
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background: #eeeef0;
          position: absolute;
          right: 0px;
          top: 0px;
        }
        .score-title {
          color: #606266;
          margin-top: 10px;
          font-size: 16px;
        }
      }
      .score-last {
        &::after {
          content: "";
          display: none;
        }
      }
      .star-content {
        height: 135px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 45px;
        .score-number {
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        & > :nth-last-child(1) {
          font-size: 14px;
          color: #606266;
          margin-top: 20px;
        }
      }
    }
    .score-box2 {
      margin-top: 20px;
      .date-picker {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        .e6-icon-arrow-b_line {
          position: absolute;
          right: 20px;
          top: 7px;
          color: #fff;
        }
        /deep/.el-date-editor {
          font-size: 14px;
          background: linear-gradient(to right, #17cdf2 0%, #1a96f8 100%);
          color: #fff;
          height: 30px;
          line-height: 30px;
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          width: 140px;
        }
        /deep/.el-input__inner {
          font-size: 14px;
          background: linear-gradient(to right, #17cdf2 0%, #1a96f8 100%);
          color: #fff;
          border: none;
          padding: 0px;
        }
        /deep/.el-input__prefix {
          display: none;
        }
        /deep/.el-input--mini .el-input__icon {
          display: none;
        }
      }
    }
  }
}
.form-div {
  margin-top: 15px;
}
/deep/.edit-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-input {
      width: 200px;
    }
  }
}
</style>
